module.exports = {
  siteTitle: 'Pat + Amanda',
  manifestName: 'Pat Amanda Wedding 2019',
  manifestShortName: 'Wedding',
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: '/wedding/',
  heading: 'Pat + Amanda',
  subHeading: '6-20-20'
}
